/* specific css to override the nowrap header row */
.css-lapokc {
    white-space: normal !important;
    overflow: visible !important;
    text-overflow: clip !important;
}

/* .css-10gei56 {
    min-height: 1.5rem !important;
} */

::-webkit-scrollbar-track {
background-color: transparent;
}

::-webkit-scrollbar {
right: 0;
width: 12px;
height: 12px;
}

::-webkit-scrollbar-thumb {
background-color: #999;
border-radius: 8px;
width: 12px;
height: 12px;
}

.desktop-dropdowns {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: min(100%, 350px);
    justify-content: space-between;
}

.mobile-dropdowns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}